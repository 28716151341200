import {
  DeleteOutlined,
  PlusCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Form, message, Upload } from "antd";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import Selector from "app/shared/Selector";
import Typography from "app/shared/Typography";
import { useState } from "react";
import "./UploadResult.scss";
import Papa from "papaparse";
import { apiGet, apiPost } from "app/services/apiService";

import { useDispatch } from "react-redux";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import moment from "moment";
import { parseURL } from "app/utils/helpers/common.helper";
import { API_ENDPOINT_UPLOAD_BULK_EXAM_RESULTS } from "app/scenes/results/results.constants";
export default function UploadResult(props) {
  const [selectedYear, setSelectedYear] = useState(moment().format("YYYY"));
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedExam, setSelectedExam] = useState("");

  const [parsedResults, setParsedResults] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const dispatch = useDispatch();

  const removeRowInvite = (itemId) => {
    setParsedResults(parsedResults.filter((item) => item.id !== itemId));
  };

  const handleUploadResults = async () => {
    const response = await apiPost(
      parseURL(API_ENDPOINT_UPLOAD_BULK_EXAM_RESULTS, { examId: selectedExam }),
      {
        results: parsedResults,
        classId: selectedClass,
        subjects: subjects,
      }
    );

    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      props.onCreate && props.onCreate();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
  };

  const beforeUpload = (file) => {
    Papa.parse(file, {
      skipEmptyLines: true,
      complete: function (results) {
        console.log("csf result", results);
        addParsedEntry(results.data);
      },
    });
  };

  const addParsedEntry = (entries) => {
    let newEntries = [];
    let subjects = [];

    entries.forEach((element, index) => {
      if (index === 0) {
        let removedFirst = element;
        removedFirst.shift();
        subjects = removedFirst;
      } else {
        let sid = element[0];
        element.forEach((result, resultIndex) => {
          if (resultIndex !== 0) {
            newEntries.push({
              sid: sid,
              code: subjects[resultIndex - 1],
              marks: result,
            });
          }
        });
      }
    });
    console.log("subjects", subjects);
    console.log("entries", newEntries);
    setSubjects(subjects);
    setParsedResults(newEntries);
  };

  return (
    <div className="UploadResult">
      <div
        className="InviteSelectors"
        style={{
          borderBottom: selectedClass ? "1px solid rgb(235, 230, 230)" : "none",
        }}
      >
        <div className="SelectorContainer">
          <Selector
            placeholder="Year"
            labelKey="value"
            valueKey="key"
            serviceType="STATIC"
            onChange={(data) => setSelectedYear(data)}
            defaultValue={selectedYear}
            staticData={[
              {
                key: "2019",
                value: 2019,
              },
              {
                key: "2020",
                value: 2020,
              },
              {
                key: "2021",
                value: 2021,
              },
              {
                key: "2022",
                value: 2022,
              },
              {
                key: "2023",
                value: 2023,
              },
              {
                key: "2024",
                value: 2024,
              },
            ]}
          />
        </div>

        <div className="SelectorContainer">
          <Selector
            placeholder="Class"
            labelKey="name"
            valueKey="id"
            serviceType="CLASSES"
            onChange={(item) => setSelectedClass(item)}
            queryFilter={{ key: "year", dataValue: selectedYear }}
            disabled={!selectedYear}
            // defaultValue={inviteUser.role_id}
          />
        </div>
        <div className="SelectorContainer">
          <Selector
            placeholder="Examination"
            labelKey="name"
            valueKey="id"
            serviceType="EXAMINATION"
            onChange={(item) => setSelectedExam(item)}
            queryFilter={{ key: "classId", dataValue: selectedClass }}
            disabled={!selectedClass}
            // defaultValue={inviteUser.role_id}
          />
        </div>
      </div>
      {selectedClass && selectedExam ? (
        <Form
          name="basic"
          initialValues={{ remember: true, parsedResults }}
          onFinish={handleUploadResults}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className="formContainer">{/* {parsedResults.map()} */}</div>

          <Upload
            {...props}
            accept=".csv"
            beforeUpload={beforeUpload}
            customRequest={() => {}}
            // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
          >
            <Button height={30} size="small" icon={<UploadOutlined />}>
              Upload CSV
            </Button>
          </Upload>

          <div className="action-buttons">
            <Form.Item style={{ width: 240 }}>
              <Button
                type="primary"
                size="large"
                style={{ width: "100%" }}
                htmlType="submit"
                // loading={isLoading}
                disabled={!selectedClass}
              >
                Upload
              </Button>
            </Form.Item>
          </div>
        </Form>
      ) : null}
    </div>
  );
}
